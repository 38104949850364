;(function(uitoolkit, $) {
    'use strict';
    if (!$ || !uitoolkit) {
        return;
    }

    // variable names should be reported from global header service itself
    //  this is a workaround until that is in place
    const omnitureVariableMap = {
        // element_id: variable_name
        // primary nav click
        'primary-nav-home': 'Header.Top:Egencia.Logo.Clicked',
        'primary-nav-book': 'Header.Top.Book.Clicked',
        'primary-nav-trips': 'Header.Top:Trips.Clicked',
        'primary-nav-tools': 'Header.Top:Tools.Clicked',
        'primary-nav-admin': 'Header.Top:Admin.Clicked',
        'primary-nav-expense': 'Header.Top:Expense.Clicked',
        'primary-nav-feedback': 'Header.Top:Feedback.Clicked',
        'primary-nav-help-center': 'Header.Top:Help.Clicked',
        'primary-nav-community': 'Header.Top:Community.Link.Clicked',

        // book click listeners
        'book-flight': 'Header.Top:Book.Cat:Flights.Clicked',
        'book-hotel': 'Header.Top:Book.Cat:Hotel.Clicked',
        'book-train': 'Header.Top:Book.Cat:Rail.Clicked',
        'book-car': 'Header:Top:Book.Cat:Car.Clicked',
        'book-meeting': 'Header:Top:Book.Cat:Meeting:Clicked',
        'book-special': 'Header:Top:Book.Cat:Special.Clicked',
        'add-on-link': 'Header:Top:Book.Cat:AddOns.Clicked',

        // tools > general
        'tools-general-help-center-link': 'Header.Top:Tools.Cat:General.Sub:HelpCenter',
        'tools-general-legacy-flight-price-guarantee': 'Header.Top:Tools.Cat:General.Sub:FlightPriceGuarantee',
        'tools-general-legacy-flight-status': 'Header.Top:Tools.Cat:General.Sub:FlightStatus',
        'tools-general-legacy-currency-converter': 'Header.Top:Tools.Cat:General.Sub:CurrencyConverter',
        'tools-general-legacy-visa-and-passport': 'Header.Top:Tools.Cat:General.Sub:VisaAndPassportInfo',
        'tools-general-connect-community-link': 'Header.Top:Tools.Cat:General.Sub:ConnectCommunity',
        'travel-advisor-link': 'Header.Top:Tools.Cat:General.Sub:EgenciaTravelAdvisor',
        'travel-advisor': 'Header.Top:Tools.Cat:General.Sub:EgenciaTravelAdvisor',
        'travel-news-link': 'Header.Top:Tools.Cat:General.Sub:TravelNews',
        'traveler-tracker-link': 'Header.Top:Tools.Cat:General.Sub:TravelerTracker',
        'tools-general-group-travel-link': 'Header.Top:Tools.Cat:General.Sub:GroupTravel',

        // tools > travel management
        'trip-approval-requests': 'Header.Top:Tools.Cat:TravelMgmt.Sub:TripApprovalReq',
        'global-approvals-request': 'Header.Top:Tools.Cat:TravelMgmt.Sub:TripApprovalReq',
        'awaiting-approval-trips': 'Header.Top:Tools.Cat:TravelMgmt.Sub:TripsWaitingApproval',
        'refused-trips': 'Header.Top:Tools.Cat:TravelMgmt.Sub:TripsRefused',
        'trip-controller': 'Header.Top:Tools.Cat:TravelMgmt.Sub:TripController',
        'travel-resource': 'Header.Top:Tools.Cat:TravelMgmt.Sub:TMResourceCenter',

        // tools > travel policy
        'approval-configuration': 'Header.Top:Tools.Cat:Policy.Sub:GlbApprovalConfig',
        'globalapproval-configuration': 'Header.Top:Tools.Cat:Policy.Sub:GlbApprovalConfig',
        'custom-reason': 'Header.Top:Tools.Cat:Policy.Sub:CustomReasonCodes',
        'flight-display': 'Header.Top:Tools.Cat:Policy.Sub:FlightResultsDisplay',
        'supplier-policy': 'Header.Top:Tools.Cat:Policy.Sub:SupplierPolicy',
        'traveler-group': 'Header.Top:Tools.Cat:Policy.Sub:TravelerGroup',
        'traveller-group': 'Header.Top:Tools.Cat:Policy.Sub:TravelerGroup',
        'preferred-hotel': 'Header.Top:Tools.Cat:Policy.Sub:PreferredAndNegotiatedHotels',
        'tripcontroller-configuration': 'Header.Top:Tools.Cat:Policy.Sub:TripControllerConfig',
        'traveler-policy': 'Header:Top:Tools.Cat:Policy.Sub:TravelPolicy',

        // tools > user management
        'manage-users': 'Header.Top:Tools.Cat:UserMgmt.Sub:ManageUsers',
        'global-user-search': 'Header.Top:Tools.Cat:UserMgmt.Sub:GlbUserSearch',
        'user-search-global': 'Header.Top:Tools.Cat:UserMgmt.Sub:GlbUserSearch',
        'user-create': 'Header.Top:Tools.Cat:UserMgmt.Sub:CreateUsers',
        'user-creation': 'Header.Top:Tools.Cat:UserMgmt.Sub:CreateUsers',
        'global-user-self-registration': 'Header.Top:Tools.Cat:UserMgmt.Sub:GlbSelfRegister',
        'create-users': 'Header.Top:Tools.Cat:UserMgmt.Sub:CreateGuestUsers',
        'occasional-traveller-creation': 'Header.Top:Tools.Cat:UserMgmt.Sub:CreateGuestUsers',
        'global-bdm': 'Header.Top:Tools.Cat:UserMgmt.Sub:ManageBulkData',
        'global-bulk-data': 'Header.Top:Tools.Cat:UserMgmt.Sub:ManageBulkData',
        'guest-users': 'Header.Top:Tools.Cat:UserMgmt.Sub:GuestUsers',
        'arranger-group': 'Header.Top:Tools.Cat:UserMgmt.Sub:ArrangerGroup',
        'search-for-users': 'Header.Top:Tools.Cat:UserMgmt.Sub:UserSearch',

        // tools > company management
        'company_settings': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CompanySettings',
        'invoice_settings': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:InvoiceSettings',
        'company-assist-notes': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CompanyAssistNotes',
        'company-favorite-links': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:FavLinks',
        'company-profile': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:Profile',
        'department-codes': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:DeptCodes',
        'custom-data': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CustomData',
        'company-payment-setup': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:PaymentSetup',
        'billing-entity': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:BillingEntity',
        'cdf-mapping': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CustomerDataMapping',
        'towncar-mapping': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:TowncarMapping',
        'central-billing': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:Billing',
        'customize-general-tools': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:GeneralToolsMenu',
        'cost-center': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CostCentre',
        'customization': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:Customization',
        'security': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:Security',
        'company-address': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CompanyAddressList',
        'traveler-message': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:TravelerMessages',
        'custom-locations': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CustomLocations',
        'invoices': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:Invoices',
        'billingdata': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:InvoiceData',
        'communication': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:Communication',
        'car-settings': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:CarSettings',
        'api-management': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:APIManagement',
        'data-feed-management': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:DataFeedManagement',
        'broadcast-message': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:BroadcastMessages',
        'sustainability_settings': 'Header.Top:Tools.Cat:CompanyMgmt.Sub:SustainabilitySettings',
        'airline_rewards_setting' : 'Header.Top:Tools.Cat:CompanyMgmt.Sub:AirlineRewards',


        // tools > reporting
        'reporting': 'Header.Top:Tools.Cat:Reporting.Sub:Reporting',
        'companySetupReporting': 'Header.Top:Tools.Cat:Reporting.Sub:CompanySetupReport',

        // tools > risk management
        'traveler-tracker': 'Header.Top:Tools.Cat:RiskManagement.Sub:TravelerTracker',

        // tools > expense
        'customer-invoice-care': 'Header.Top:Tools.Cat:Expense.Sub:CustomerInvoiceCare',
        'mobile-expense': 'Header.Top:Tools.Cat:Expenses.Sub:MobileXpense',
        'my-invoices': 'Header.Top:Tools.Cat:Expenses.Sub:MyInvoices',
        'concur-menu': 'Header.Top:Tools.Cat:Expenses.Sub:ConcurIntegrationSettings',
        'company-concur-link': 'Header.Top:Tools.Cat:Expense.Sub:Expense',

        // admin click listeners
        'admin-tools': 'Header.Top:Admin.Cat:AdminTools',
        'user-search': 'Header.Top:Admin.Cat:UserSearch',
        'company-search': 'Header.Top:Admin.Cat:CompanySearch',
        'incident-form': 'Header.Top:Admin.Cat:IncidentForm',
        'create-company': 'Header.Top:Admin.Cat:CreateCompany',
        'company-tools': 'Header.Top:Admin.Cat:CompanyTools',
        'admin-guest-users': 'Header.Top:Admin.Cat:GuestUserSearch',
        'non-standard-booking': 'Header.Top:Admin.Cat:NSB',
        'business-review-center': 'Header.Top:Admin.Cat:TravelProgramReviewCenter',
        'config-management': 'Header.Top:Admin.Cat:ConfigMgmt',
        'special-request': 'Header.Top:Admin.Cat:SpecialRequest',
        'shell-pnr': 'Header.Top:Admin.Cat:ShellPNR',
        'webhelp-contact': 'Header.Top:Admin.Cat:ContactEGTS',
        'billback': 'Header.Top:Admin.Cat:Billback',
        'new-incident': 'Header.Top:Admin.Cat:ContactGCOSupport',

        // profile
        'account-nav-mobile-toggle': 'Header.Profile',
        'account-nav-desktop-toggle': 'Header.Profile',
        'user-profile': 'Header.Profile.UserProfile',
        'log-out': 'Header.Profile.LogOut',
        'switch-profile': 'Header.Profile.SwitchProfile',

        // footer
        'about-egencia': 'Footer.About',
        'privacy-policy': 'Footer.PrivacyPolicy',
        'promise': 'Footer.EgenciaPromise',
        'terms-use': 'Footer.Terms',
        'mobile-app-download': 'Footer.MobileApp',
    };

    // list of handlers for tracking click events
    const clickListeners = [
        // primary nav
        '#global-header #primary-nav-home',
        '#global-header #primary-nav-book',
        '#global-header #primary-nav-trips',
        '#global-header #primary-nav-tools',
        '#global-header #primary-nav-expense',
        '#global-header #primary-nav-feedback',
        '#global-header #primary-nav-community',
        '#global-header #primary-nav-admin',

        // submenus
        '#global-header .book-submenu .menu-item a',
        '#global-header .tools-submenu .menu-item a',
        '#global-header .admin-submenu .menu-item a',
        '#global-header .account-nav-toggle',

        // footer
        '#footer .site-navigation a',

        // profile menu tooltip contents
        '.account-nav a',
    ].join(',');

    // set up some variables and properties for later use
    const eVar61 = window.location.hostname;
    const linkName = 'RFRR Action Link';
    const linkTrackVars = 'eVar60,eVar61';
    const linkTrackEvents = 'event33,event34';

    $(function($) {
        /**
         * Handler function for Zepto event delegation (it is only called with `$(...).on(<event_name>, trackHeader);`)
         * @param {Event} e - Event delegated by zepto
         */
        function trackHeader(e) {
            // capture linkObj for later use and is used for link id
            const linkObj = e.currentTarget;
            const {id, href} = linkObj;

            // Hack to Tools > General > custom bookmark with iterated index id
            let key = '';
            if (href.includes('https://www.egencia.com/public/us/egencia-promise/')) {
                key = 'tools-general-legacy-flight-price-guarantee'
            } else if (href.includes('/pub/agent.dll?qscr=flin&rfrr=-630')) {
                key = 'tools-general-legacy-flight-status'
            } else if (href.includes('/pub/agent.dll?qscr=curc&rfrr=-634')) {
                key = 'tools-general-legacy-currency-converter'
            } else if (href.includes('/pub/agent.dll?qscr=cibt')) {
                key = 'tools-general-legacy-visa-and-passport'
            } else {
                key = id
            }
            // in the case the id is a number, the user has clicked on the "switch profile" link 
            //  the id attribute is the profile's product id
            //  if there is a miss in the omnitureVariableMap, its a link we aren't tracking yet
            const variableName = omnitureVariableMap[isNaN(key) ? key : 'switch-profile'] || 'Header.Other';

            // set up the other variables to track
            const prop16 = variableName;
            const eVar28 = variableName;
            const eVar60 = new Date().toISOString().replace('T', ' ').replace('Z', ' GMT')

            // join the variables into the linktrackvars object. these get set onto analytics
            const vars = {prop16, eVar28, eVar60, eVar61};

            // can't use 'a' event because we need custom eVar60 and eVar61
            //  everything else is identical and unchanged
            uitoolkit.analytics.set(vars).track('o', {linkName, linkObj, linkTrackEvents, linkTrackVars});
        };

        // delegate through body because some links are in different places on the body (#global-header, #footer, and tooltips)
        $('body').on('click', clickListeners, trackHeader);
        
        // set the use_global_header_service ab test. relies on webapps calling uitk.analytics.send() to track
        uitoolkit.analytics.setAbTests('use_global_header_service');
    });
})(window.uitk, window.$);
