/**
 * Expweb Site Analytics
 * 
 * THIS FILE IS GENERATED AUTOMATICALLY
 * 
 * Please follow the instructions at site-analytics/README.md to change it.
 *
 * Last build: Thu May 14 2015 17:48:22 GMT+0200 (Central Europe Daylight Time)
 */

/* eslint-env browser */
/* global s_gi, dctk */

//Exclude Safari preview window
if (navigator.userAgent.match(/4 Public Beta Safari/)|| navigator.loadPurpose === "preview"){
     s_exp_account = "devnull";
     window.suppressInlineOmniture = true;
}

// set s_exp_* values to legacy settings if needed
if(! window.s_exp_account) { var s_exp_account = window.s_account; }
if(! window.s_exp_EAPID) { var s_exp_EAPID = window.s_EAPID; }
if(! window.s_exp_BrandEAPID) { var s_exp_BrandEAPID = window.s_BrandEAPID; }

var hostname = hostname?? window.location.hostname;
var trackServerName = trackServerName?? "metrics.egencia.com";
s_exp_account = determineAccount();
var s_exp = s_gi(s_exp_account);

s_exp.trackExternalLinks = false;
s_exp.linkInternalFilters = document.domain;
s_exp.linkLeaveQueryString = false;
s_exp.trackDownloadLinks = true;
s_exp.linkDownloadFileTypes = "exe,zip,wav,mp3,mov,mpg,avi,wmv,doc,pdf,xls";
s_exp.trackInlineStats = false;
s_exp.linkTrackVars = "None";
s_exp.linkTrackEvents = "None";
s_exp.charSet = window.s_charSet || "ISO-8859-1";
s_exp.visitor= Visitor.getInstance("C9172B17642B00F60A495FA2@AdobeOrg");
s_exp.trackingServer= trackServerName;
s_exp.trackingServerSecure= trackServerName;

function determineAccount()
{
    if(EG.analytics && EG.analytics.account) {
        return EG.analytics.account;
    } else {
        // For dotcom page which does not have EG
        return 'expediaegenciaglobal';
    }
}

function eVar60FormatDate(){
	// date formating
	var currentDate, currentYear, currentMonth, currentHour, currentMinutes, currentSeconds, currentMilliSeconds;
    var calendarDate = new Date();
    currentYear = calendarDate.getUTCFullYear();
    currentMonth = calendarDate.getUTCMonth()+1;
    currentDate = calendarDate.getUTCDate();
    currentHour = calendarDate.getUTCHours();
    currentMinutes = calendarDate.getUTCMinutes();
    currentSeconds = calendarDate.getUTCSeconds();
    currentMilliSeconds = calendarDate.getUTCMilliseconds();
    currentMonth = ("0" + currentMonth).slice(-2);
    currentDate = ("0" + currentDate).slice(-2);
    currentHour = ("0" + currentHour).slice(-2);
    currentMinutes = ("0" + currentMinutes).slice(-2);
    currentSeconds = ("0" + currentSeconds).slice(-2);
    currentMilliSeconds = ("00" + currentMilliSeconds).slice(-3);
    return [currentYear, currentMonth, currentDate].join('-')+' '+
           [currentHour, currentMinutes, currentSeconds].join(':')+'.'+
           [currentMilliSeconds, "GMT"].join(' ');
}
s_exp.eVar60 = eVar60FormatDate();


// Begin Global Link Tracking Code
/* get track object from s_exp in case dctk is in the enviroment (this is an edge case) */
function getTrackVarsFroms_exp(s_exp){
    var tmpTrackVars = {};
    for (var key in s_exp){
        if (s_exp.hasOwnProperty(key)){
            if ((/^eVar\d+|prop\d+/g).test(key)) {
                tmpTrackVars[key] = s_exp[key];
            }
        }
    }
    return tmpTrackVars;
}

	
/* Link Tracking Functions */
function s_exp_trackClick(linkObj,linkType,linkID,regionID,deprtDate,arrivDate,amenityVal,srchDetails,traffic17,traffic18,clickedPosition,traffic19,traffic20,traffic29) {
  if(linkType=="i"){
    // save in cookie for next page (future feature)
  } else if(linkType=="e") {
    s_exp.trackExternalLinks=false; // used to avoid doublecounting
    s_exp.linkTrackVars='prop16,eVar28,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
        if ((typeof dctk !== 'undefined') && (typeof dctk.trackExit !== 'undefined')){
            dctk.trackExit(linkID, linkObj, getTrackVarsFroms_exp(s_exp));
        } else {
            s_exp.tl(linkObj,'e','RFRR Exit Link');
        }

  } else if(linkType=="d") {
	s_exp.trackDownloadLinks=false;
	s_exp.linkTrackVars = 'prop11,prop12,prop16,eVar18,eVar28,eVar34,eVar17';
	s_exp.prop16 = linkID;
	s_exp.eVar28 = linkID;
	s_exp.tl(true,'d','INS:TermsLink Downloads');
  } else if(linkType=="a") {
        s_exp.linkTrackVars='prop12,prop16,eVar28,eVar61,prop75';
        s_exp.linkTrackEvents='None';
        s_exp.prop16=linkID;
        s_exp.eVar28=linkID;
// if(s_exp.pageName == "Homepage"){s_exp.eVar60 = s_exp.eVar28;}
        if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
            dctk.trackAction(linkID, linkObj, getTrackVarsFroms_exp(s_exp));
        } else {
            s_exp.tl(true,'o','RFRR Action Link');
        }
    } else if(linkType=="amenityCBClick") {
      s_exp.linkTrackVars='eVar45,eVar61';
      s_exp.linkTrackEvents='None';
      s_exp.eVar45=linkID;
        if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
            dctk.trackEvent('o', 'Amenities Search', getTrackVarsFroms_exp(s_exp));
        } else {
            s_exp.tl(true,'o','Amenities Search');
        }
  } else if(linkType=="refineSearch") {
    s_exp.linkTrackVars='prop2,eVar2,prop4,eVar4,prop5,eVar5,prop6,eVar6,prop16,eVar28,eVar45,eVar47,prop17,prop18,prop19,prop20,prop29,eVar34,prop34,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
    s_exp.prop2='hotels';
    s_exp.eVar2="D=c2";
    s_exp.prop4=regionID;
    s_exp.eVar4="D=c4";
    s_exp.prop5=arrivDate;
    s_exp.prop6=deprtDate;
    s_exp.eVar45=amenityVal;
    s_exp.eVar47=srchDetails;
    s_exp.prop17=traffic17;
    s_exp.prop18=traffic18;
    s_exp.prop19=traffic19;
    s_exp.prop20=traffic20;
    s_exp.prop29=traffic29;
        if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
            dctk.trackEvent('o', 'Refine Search Link', getTrackVarsFroms_exp(s_exp));
        } else {
            s_exp.tl(true,'o','Refine Search Link');
        }
  } else if(linkType=="hotelPosition") {
    s_exp.linkTrackVars='prop16,eVar28,eVar39,eVar61,prop75';
    s_exp.linkTrackEvents='None';
    s_exp.prop16=linkID;
    s_exp.eVar28=linkID;
    s_exp.eVar39=clickedPosition;
        if ((typeof dctk !== 'undefined') && (typeof dctk.trackAction !== 'undefined')){
            dctk.trackEvent('o', 'Clicked hotel position', getTrackVarsFroms_exp(s_exp));

        } else {
            s_exp.tl(true,'o','Clicked hotel position');
        }
  }
    return;
}
s_exp.trackClick=s_exp_trackClick;
/* End Link Tracking Functions */

/* Abacus Tracking */
function PLSetABTestIDAndValue(v) {
  if( s_exp.prop34 == "" || s_exp.prop34 == undefined ) {
        s_exp.prop34 = v;
        s_exp.eVar34 = v;
    } else {
        if( s_exp.prop34.indexOf(v)==(-1) ) {
            s_exp.prop34 = s_exp.prop34 + "|" + v;
            s_exp.eVar34 = s_exp.eVar34 + "|" + v;
        }
    }
}



/* Plugin Config */
s_exp.usePlugins=true
function s_exp_doPlugins(s_exp) {

//Maybe we'll want to do siteSpect on Egencia?
   if(typeof window.s_exp_siteSpect !== 'undefined'){
       s_exp.eVar23 = window.s_exp_siteSpect;
    }

//Good to have cookies all parsed out and pretty
var cookies = document.cookie.split(/;\s*/);

//Sometimes we like to reference s_exp.referrer
s_exp.referrer = document.referrer;

s_exp.eVar61 = hostname.replace(/([\w\d]+.)/,"");

    s_exp.eVar50 = s_exp.eVar50 || 'desktop';

  if (/karmalab.net/.test(hostname) || /egencia-test/.test(hostname)){
      s_exp.tpid = 'dev';
    }


  // Set s_exp.prop12 to GUID from MC1 cookie
  // This overrides any s_exp.prop12 set in scripts in the HTML by the server, as long as the cookie is available.
  // s_exp.prop12 is often set incorrectly by the FTLs as ${this.getGuid()}
  var guid = "";
  if (s_exp.prop12 != undefined) guid = s_exp.prop12;

  for(var i = 0, n = cookies.length; i < n; i++) {
    if(cookies[i].indexOf("MC1=") == 0) {
      guid = cookies[i].split("=")[2];
      break;
    }
  }
  s_exp.prop12 = guid;
  // End s_exp.prop12 to GUID


  /* override referrer if needed */
  s_exp.referrer=s_exp.Util.getQueryParam("origref");

  /* server */
  s_exp.server=document.domain;



  /* rfrr IDs */
  if(!s_exp.eVar28) {
    if(s_exp.Util.getQueryParam("rfrr").length > 0)
    s_exp.eVar28=s_exp.Util.getQueryParam("rfrr")
    if(!s_exp.eVar28 && window.qscr_rfrr) {
      s_exp.eVar28=qscr_rfrr;
    }
  }
  s_exp.prop16=s_exp.eVar28;

  /* pageName in conversion variables */
  s_exp.eVar18=s_exp.pageName;
  s_exp.eVar17 = sessionStorage.getItem('uitk.previousPageName') ? sessionStorage.getItem('uitk.previousPageName'): '';
  if(typeof s_exp.pageName !== 'undefined' && s_exp.pageName) {
     sessionStorage.setItem('uitk.previousPageName', s_exp.pageName);
  }

  /* Mobile device orientation tracking */
  s_exp.prop39 = s_exp.mobileOrientation;

  //Get Android OS version.  If iOS don't care for now, just record as iOS.
  if(navigator.userAgent.match(/Android [\d+\.]{3,5}/) !== null && navigator.userAgent.match(/Android [\d+\.]{3,5}/).length > 0){
      s_exp.prop66 = navigator.userAgent.match(/Android [\d+\.]{3,5}/)[0];
  } else if (/iPhone/.test(navigator.userAgent)){
      s_exp.prop66 = 'iOS';
  }

  // Keep expUserId cookie and prop13 in sync. This code should be kept in sync
  // with DCTK lib.js. See https://mingle/projects/ewe_data_capture_solutions/cards/2443
  // for details.
  
};

s_exp.doPlugins=s_exp_doPlugins;

/** ************************ PLUGINS SECTION ************************ */

/**
 * Check Mobile Orientation Created - Fraser Kemp
 */

s_exp.checkMobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
s_exp.mobileOrientation = (s_exp.checkMobile) ? (window.innerHeight > window.innerWidth) ? "Portrait" : "Landscape" : "" ;

/**
 * Function to clear all omniture variables.
 *
 * @type {*}
 */
s_exp.clearOmnitureObject = function(){
    for(var i = 0; i < 100; i++) { s_exp['prop' + i] = ''; s_exp['eVar' + i] = ''; }
    s_exp.products = ''; s_exp.events= ''; s_exp.linkTrackVars = ''; s_exp.server = '';
    s_exp.channel = ''; s_exp.pageName = ''; s_exp.pageType = '';
    s_exp.campaign = ''; s_exp.purchaseID = '';
}

/******************************************* BEGIN CODE TO DEPLOY *******************************************/
/* Adobe Consulting Plugin: getValOnce v3.1 */
function getValOnce(vtc, cn, et, ep) {
  var e = vtc, i = cn, t = et, n = ep;
  if (arguments && "-v" === arguments[0]) return {plugin: "getValOnce", version: "3.1"};
  var o = function () {
    if (void 0 !== window.s_c_il) {
      for (var e, i = 0; i < window.s_c_il.length; i++) if ((e = window.s_c_il[i])._c && "s_c" === e._c) return e
    }
  }();
  if (void 0 !== o && (o.contextData.getValOnce = "3.1"), window.cookieWrite = window.cookieWrite || function (e, i, t) {
    if ("string" == typeof e) {
      var n = window.location.hostname, o = window.location.hostname.split(".").length - 1;
      if (n && !/^[0-9.]+$/.test(n)) {
        o = 2 < o ? o : 2;
        var r = n.lastIndexOf(".");
        if (0 <= r) {
          for (; 0 <= r && 1 < o;) r = n.lastIndexOf(".", r - 1), o--;
          r = 0 < r ? n.substring(r) : n
        }
      }
      if (g = r, i = void 0 !== i ? "" + i : "", t || "" === i) {
        if ("" === i && (t = -60), "number" == typeof t) {
          var f = new Date;
          f.setTime(f.getTime() + 6e4 * t)
        } else f = t
      }
      return !!e && (document.cookie = encodeURIComponent(e) + "=" + encodeURIComponent(i) + "; path=/;" + (t ? " expires=" + f.toUTCString() + ";" : "") + (g ? " domain=" + g + ";" : ""), "undefined" != typeof cookieRead) && cookieRead(e) === i
    }
  }, window.cookieRead = window.cookieRead || function (e) {
    if ("string" != typeof e) return "";
    e = encodeURIComponent(e);
    var i = " " + document.cookie, t = i.indexOf(" " + e + "="), n = 0 > t ? t : i.indexOf(";", t);
    return (e = 0 > t ? "" : decodeURIComponent(i.substring(t + 2 + e.length, 0 > n ? i.length : n))) ? e : ""
  }, e) {
    var i = i || "s_gvo", t = t || 0, n = "m" === n ? 6e4 : 864e5;
    if (e !== cookieRead(i)) {
      var r = new Date;
      return r.setTime(r.getTime() + t * n), cookieWrite(i, e, 0 === t ? 0 : r), e
    }
  }
  return ""
}
/******************************************** END CODE TO DEPLOY ********************************************/
s_exp.getValOnce=getValOnce

/*
 * Plugin Utility: split v1.5
 */
s_exp.split=function(l,d){
  var i,x=0,a=new Array;while(l){i=l.indexOf(d);i=i>-1?i:l.length;a[x++]=l.substring(0,i);l=l.substring(i+d.length);}return a
};

/******************************************* BEGIN CODE TO DEPLOY *******************************************/
/* Adobe Consulting Plugin: apl (appendToList) v4.0 */
function apl(lv, va, d1, d2, cc) {
  var b = lv, d = va, e = d1, c = d2, g = cc;
  if ("-v" === b) return {plugin: "apl", version: "4.0"};
  var h = function () {
    if ("undefined" !== typeof window.s_c_il) for (var k = 0, b; k < window.s_c_il.length; k++) if (b = window.s_c_il[k], b._c && "s_c" === b._c) return b
  }();
  "undefined" !== typeof h && (h.contextData.apl = "4.0");
  window.inList = window.inList || function (b, d, c, e) {
    if ("string" !== typeof d) return !1;
    if ("string" === typeof b) b = b.split(c || ","); else if ("object" !== typeof b) return !1;
    c = 0;
    for (a = b.length; c < a; c++) if (1 == e && d === b[c] || d.toLowerCase() === b[c].toLowerCase()) return !0;
    return !1
  };
  if (!b || "string" === typeof b) {
    if ("string" !== typeof d || "" === d) return b;
    e = e || ",";
    c = c || e;
    1 == c && (c = e, g || (g = 1));
    2 == c && 1 != g && (c = e);
    d = d.split(",");
    h = d.length;
    for (var f = 0; f < h; f++) window.inList(b, d[f], e, g) || (b = b ? b + c + d[f] : d[f])
  }
  return b
}
/******************************************** END CODE TO DEPLOY ********************************************/
s_exp.apl=apl

/*
 * Plugin Utility: Replace v1.0
 */
s_exp.repl=function(x,o,n) {
  var i=x.indexOf(o),l=n.length;while(x&&i>=0){x=x.substring(0,i)+n+x.substring(i+o.length);i=x.indexOf(o,i+l)}return x
};

/* global s_exp */
(function () {

    /**
     * The difference in time DCTK will use when determing current time. This
     * effectively allows us to "time travel" during testing. See `dctk._now()`.
     */
    s_exp._timeDelta = 0;

    /**
     * @param {int} interval The time from now in milliseconds
     * @return {Date} The date past the interval
     */
    s_exp._getDateFromNow = function (interval) {
        return new Date(new Date().getTime() + interval);
    };

    /**
     * TODO Make sure it's being used throughout all DCTK
     *
     * @return {number} The current timestamp after applying `dctk._timeDelta`
     */
    s_exp._now = function () {
        return new Date().getTime() + this._timeDelta;
    };

    // Constants to make working with timespans easier
    s_exp._MINUTES = 60 * 1000;
    s_exp._HOURS = 60 * s_exp._MINUTES;
    s_exp._DAYS = 24 * s_exp._HOURS;

    /**
     * @param {string} url
     * @returns {string|undefined} `url` host or `undefined` if `url`
     *     is not an absolute URL
     */
    s_exp._getUrlHost = function (url) {
        if (/^[a-z]+:\/\/([^:/]+)/.test(url)) return RegExp.$1;
    };

    /**
     * @param {string} domain
     * @param {string} [domain] The current domain is used by default
     * @returns {string} The primary domain of domain (e.g. www.expedia.co.uk
     *     -> expedia.co.uk) or the whole domain if it can't be determined
     *
     * A more exhaustive implementation would use https://publicsuffix.org/
     * to determine what's an effective TLD but here we have enough
     * considering Expedia domains.
     *
     * TODO Ignore IPs?
     */
    s_exp._getPrimaryDomain = function (domain) {
        if (!domain) domain = document.domain;
        var matches = domain.match(/[^.]+(\.(co|com))?\.[^.]+$/);
        return matches ? matches[0] : domain;
    };


    s_exp._cookies = {
        /**
         * Sets a cookie
         *
         * @param {Object} params
         * @param {string} params.name The name of the cookie
         * @param {string} params.value The value to store
         * @param {string} [params.path]
         * @param {date} [params.expires]
         */
        set: function (params) {
            var fields = [];
            var addField = function (key, value) { fields.push(key + '=' + value); };
            addField(encodeURIComponent(params.name), encodeURIComponent(params.value));
            // We don't set the domain when it's already the current one as in certain
            // cases (e.g. localhost) the cookie won't be set even if the outcome
            // of setting it would be the same.
            if (params.domain && params.domain != document.domain) addField('domain', params.domain);
            if (params.path) addField('path', params.path);
            if (params.maxAge) params.expires = s_exp._getDateFromNow(params.maxAge);
            if (params.expires) addField('expires', params.expires.toUTCString());
            document.cookie = fields.join(';');
        },

        /**
         * @param {Object|string} params A list of params or the same as params.name
         * @param {string} params.name The name of the cookie
         * @param {string} [params.cookies] String containing the cookies or
         *     document.cookie by default.
         * @return {string|undefined} The value of the cookie or undefined if it
         *     doesn't exist
         */
        get: function (params) {
            if (typeof params == 'string') params = {name: params};
            if (params.cookies == null) params.cookies = document.cookie;
            var cookies = params.cookies.split(';');
            for (var i = 0; i < cookies.length; i++) {
                // All these " *" are to trim without using trim() as IE8 doesn't
                // support it. Also, "=" is considered optional as IE8 still follows
                // the now obsolete RFC 2965.
                var matches = cookies[i].match(/ *([^=]*) *(= *(.*))? */);
                var cookieName = decodeURIComponent(matches[1]);
                var cookieValue = matches[3] == null ? '' : decodeURIComponent(matches[3]);
                if (cookieName === params.name) return cookieValue;
            }
        },

        'delete': function (params) {
            this.set({
                name: params.name,
                value: '',
                path: params.path,
                expires: new Date(0)
            });
        }
    };
})();

/* global s_exp */

(function () {
    var SEARCH_ENGINE_DOMAIN_PATTERNS = ["google", "bing", "yahoo", "naver", "ask.com",
        "aol.co", "duckduckgo", "baidu", "webcrawler", "mywebsearch", "dogpile",
        "msn", "daum.net", "comcast.net", "docomo.ne.jp", "libero.it", "avg.com",
        "suche.web.de", "virgilio.it", "rakuten"];

    var BRANDED_LANDING_PAGE_NAMES = ["Homepage", "page.Hotels", "page.Cars", "page.Flights",
        "page.Packages", "page.Expedia-Holiday", "page.Short-Breaks", "last-minute",
        "page.Travel.Deals", "page.Activities", "bolt-on:flights:launch", "page.Cruise",
        "/deals", "/p/vacation-packages", "page.Cruises", "page.Deals", "page.Rewards",
        "groupon.tab", "/p/info-other/expedia-coupon.htm", "p/promos/expedia-summer",
        "page.domestic", "tools/itinerary", "p/promos/campaign", "rental-car"];

    // See dctk._urlHasChanged()
    var originalUrl = location.href;

    /**
     * @returns {string|undefined} The domain of the search engine
     *     that redirected the user to the current page, or `undefined`
     *     if the user doesn't come from a search engine.
     */
    s_exp._getSearchEngineDomain = function (referrer) {
        if (referrer == null) referrer = s_exp._getReferrer();
        var host = s_exp._getUrlHost(referrer);
        var primaryDomain = host && s_exp._getPrimaryDomain(host);
        for (var i = 0; i < SEARCH_ENGINE_DOMAIN_PATTERNS.length; i++) {
            if ((primaryDomain || '').match(SEARCH_ENGINE_DOMAIN_PATTERNS[i])) {
                return primaryDomain;
            }
        }
    };

    /**
     * @returns {boolean} Whether the current page is a branded
     *     landing page.
     */
    s_exp._isBrandedLandingPage = function (pageName) {
        if (!pageName) pageName = s_exp.pageName;
        for (var i = 0; i < BRANDED_LANDING_PAGE_NAMES.length; i++) {
            if (pageName == BRANDED_LANDING_PAGE_NAMES[i]) return true;
        }
    };

    /**
      * @returns {string} The original HTTP referer or an empty string if the original
      *     URL has changed
      */
    s_exp._getReferrer = function () {
        if (this._urlHasChanged()) {
            // Ideally we would return the URL prior to the change but it doesn't
            // seem possible to track it properly. We don't need it anyway.
            return '';
        }
        return document.referrer;
    };

    /**
     * @returns {boolean} Whether the URL has changed since the page load (e.g.
     *     because history.pushState() was called)
     */
    s_exp._urlHasChanged = function () {
        return location.href != originalUrl;
    };
})();
/* eslint-env browser */
/* global s_exp */

(function () {
    /**
     * Legacy interface for getQueryParam()
     *
     * See http://microsite.omniture.com/t2/help/en_US/sc/implement/getQueryParam.html
     * and http://microsite.omniture.com/t2/help/en_US/sc/implement/util_getqueryparam.html
     *
     * @param {String} params
     * @param {String} [delimiter] Ignored as the new version doesn't support it
     * @param {String} [url]
     */
    s_exp.getQueryParam = function(params, delimiter, url) {
        params = params.split(',');
        var values = [];
        for (var i = 0; i < params.length; i++) {
            var value = getSingleQueryParam(params[i], url);
            if (value.length) values.push(value);
        }
        return values.join(delimiter || '');
    };

    var getSingleQueryParam = function(param, url) {

        var getQueryString = function (url) {
            var matches = url.match(/\?([^#]*)(#.*)?$/);
            return matches ? matches[1] : '';
        };

        var lowerCaseQueryStringNames = function (queryString) {
            var params = queryString.split('&');
            for (var i = 0; i < params.length; i++) {
                var parts = params[i].split('=');
                parts[0] = parts[0].toLowerCase();
                params[i] = parts.join('=');
            }
            return params.join('&');
        };

        // Notice the delimiter argument in AppMeasurement's version doesn't 
        // correspond to H's one.
        var normalizedQueryString = '?' + lowerCaseQueryStringNames(getQueryString(url || location.href));
        return s_exp.Util.getQueryParam(param.toLowerCase(), normalizedQueryString /* , delimiter */);
    };
})();
/*
 ============== DO NOT ALTER ANYTHING BELOW THIS LINE ! ===============

AppMeasurement for JavaScript version: 2.10.0
Copyright 1996-2016 Adobe, Inc. All Rights Reserved
More info available at http://www.adobe.com/marketing-cloud.html
*/
function AppMeasurement(r){var a=this;a.version="2.10.0";var k=window;k.s_c_in||(k.s_c_il=[],k.s_c_in=0);a._il=k.s_c_il;a._in=k.s_c_in;a._il[a._in]=a;k.s_c_in++;a._c="s_c";var p=k.AppMeasurement.Nb;p||(p=null);var n=k,m,s;try{for(m=n.parent,s=n.location;m&&m.location&&s&&""+m.location!=""+s&&n.location&&""+m.location!=""+n.location&&m.location.host==s.host;)n=m,m=n.parent}catch(u){}a.D=function(a){try{console.log(a)}catch(b){}};a.Ha=function(a){return""+parseInt(a)==""+a};a.replace=function(a,b,d){return!a||
0>a.indexOf(b)?a:a.split(b).join(d)};a.escape=function(c){var b,d;if(!c)return c;c=encodeURIComponent(c);for(b=0;7>b;b++)d="+~!*()'".substring(b,b+1),0<=c.indexOf(d)&&(c=a.replace(c,d,"%"+d.charCodeAt(0).toString(16).toUpperCase()));return c};a.unescape=function(c){if(!c)return c;c=0<=c.indexOf("+")?a.replace(c,"+"," "):c;try{return decodeURIComponent(c)}catch(b){}return unescape(c)};a.ub=function(){var c=k.location.hostname,b=a.fpCookieDomainPeriods,d;b||(b=a.cookieDomainPeriods);if(c&&!a.za&&!/^[0-9.]+$/.test(c)&&
(b=b?parseInt(b):2,b=2<b?b:2,d=c.lastIndexOf("."),0<=d)){for(;0<=d&&1<b;)d=c.lastIndexOf(".",d-1),b--;a.za=0<d?c.substring(d):c}return a.za};a.c_r=a.cookieRead=function(c){c=a.escape(c);var b=" "+a.d.cookie,d=b.indexOf(" "+c+"="),f=0>d?d:b.indexOf(";",d);c=0>d?"":a.unescape(b.substring(d+2+c.length,0>f?b.length:f));return"[[B]]"!=c?c:""};a.c_w=a.cookieWrite=function(c,b,d){var f=a.ub(),e=a.cookieLifetime,g;b=""+b;e=e?(""+e).toUpperCase():"";d&&"SESSION"!=e&&"NONE"!=e&&((g=""!=b?parseInt(e?e:0):-60)?
(d=new Date,d.setTime(d.getTime()+1E3*g)):1===d&&(d=new Date,g=d.getYear(),d.setYear(g+2+(1900>g?1900:0))));return c&&"NONE"!=e?(a.d.cookie=a.escape(c)+"="+a.escape(""!=b?b:"[[B]]")+"; path=/;"+(d&&"SESSION"!=e?" expires="+d.toUTCString()+";":"")+(f?" domain="+f+";":""),a.cookieRead(c)==b):0};a.rb=function(){var c=a.Util.getIeVersion();"number"===typeof c&&10>c&&(a.unsupportedBrowser=!0,a.gb(a,function(){}))};a.gb=function(a,b){for(var d in a)a.hasOwnProperty(d)&&"function"===typeof a[d]&&(a[d]=b)};
a.L=[];a.ba=function(c,b,d){if(a.Aa)return 0;a.maxDelay||(a.maxDelay=250);var f=0,e=(new Date).getTime()+a.maxDelay,g=a.d.visibilityState,h=["webkitvisibilitychange","visibilitychange"];g||(g=a.d.webkitVisibilityState);if(g&&"prerender"==g){if(!a.ca)for(a.ca=1,d=0;d<h.length;d++)a.d.addEventListener(h[d],function(){var c=a.d.visibilityState;c||(c=a.d.webkitVisibilityState);"visible"==c&&(a.ca=0,a.delayReady())});f=1;e=0}else d||a.o("_d")&&(f=1);f&&(a.L.push({m:c,a:b,t:e}),a.ca||setTimeout(a.delayReady,
a.maxDelay));return f};a.delayReady=function(){var c=(new Date).getTime(),b=0,d;for(a.o("_d")?b=1:a.ra();0<a.L.length;){d=a.L.shift();if(b&&!d.t&&d.t>c){a.L.unshift(d);setTimeout(a.delayReady,parseInt(a.maxDelay/2));break}a.Aa=1;a[d.m].apply(a,d.a);a.Aa=0}};a.setAccount=a.sa=function(c){var b,d;if(!a.ba("setAccount",arguments))if(a.account=c,a.allAccounts)for(b=a.allAccounts.concat(c.split(",")),a.allAccounts=[],b.sort(),d=0;d<b.length;d++)0!=d&&b[d-1]==b[d]||a.allAccounts.push(b[d]);else a.allAccounts=
c.split(",")};a.foreachVar=function(c,b){var d,f,e,g,h="";e=f="";if(a.lightProfileID)d=a.P,(h=a.lightTrackVars)&&(h=","+h+","+a.ga.join(",")+",");else{d=a.g;if(a.pe||a.linkType)h=a.linkTrackVars,f=a.linkTrackEvents,a.pe&&(e=a.pe.substring(0,1).toUpperCase()+a.pe.substring(1),a[e]&&(h=a[e].Lb,f=a[e].Kb));h&&(h=","+h+","+a.G.join(",")+",");f&&h&&(h+=",events,")}b&&(b=","+b+",");for(f=0;f<d.length;f++)e=d[f],(g=a[e])&&(!h||0<=h.indexOf(","+e+","))&&(!b||0<=b.indexOf(","+e+","))&&c(e,g)};a.q=function(c,
b,d,f,e){var g="",h,l,k,q,m=0;"contextData"==c&&(c="c");if(b){for(h in b)if(!(Object.prototype[h]||e&&h.substring(0,e.length)!=e)&&b[h]&&(!d||0<=d.indexOf(","+(f?f+".":"")+h+","))){k=!1;if(m)for(l=0;l<m.length;l++)h.substring(0,m[l].length)==m[l]&&(k=!0);if(!k&&(""==g&&(g+="&"+c+"."),l=b[h],e&&(h=h.substring(e.length)),0<h.length))if(k=h.indexOf("."),0<k)l=h.substring(0,k),k=(e?e:"")+l+".",m||(m=[]),m.push(k),g+=a.q(l,b,d,f,k);else if("boolean"==typeof l&&(l=l?"true":"false"),l){if("retrieveLightData"==
f&&0>e.indexOf(".contextData."))switch(k=h.substring(0,4),q=h.substring(4),h){case "transactionID":h="xact";break;case "channel":h="ch";break;case "campaign":h="v0";break;default:a.Ha(q)&&("prop"==k?h="c"+q:"eVar"==k?h="v"+q:"list"==k?h="l"+q:"hier"==k&&(h="h"+q,l=l.substring(0,255)))}g+="&"+a.escape(h)+"="+a.escape(l)}}""!=g&&(g+="&."+c)}return g};a.usePostbacks=0;a.xb=function(){var c="",b,d,f,e,g,h,l,k,q="",m="",n=e="";if(a.lightProfileID)b=a.P,(q=a.lightTrackVars)&&(q=","+q+","+a.ga.join(",")+
",");else{b=a.g;if(a.pe||a.linkType)q=a.linkTrackVars,m=a.linkTrackEvents,a.pe&&(e=a.pe.substring(0,1).toUpperCase()+a.pe.substring(1),a[e]&&(q=a[e].Lb,m=a[e].Kb));q&&(q=","+q+","+a.G.join(",")+",");m&&(m=","+m+",",q&&(q+=",events,"));a.events2&&(n+=(""!=n?",":"")+a.events2)}if(a.visitor&&a.visitor.getCustomerIDs){e=p;if(g=a.visitor.getCustomerIDs())for(d in g)Object.prototype[d]||(f=g[d],"object"==typeof f&&(e||(e={}),f.id&&(e[d+".id"]=f.id),f.authState&&(e[d+".as"]=f.authState)));e&&(c+=a.q("cid",
e))}a.AudienceManagement&&a.AudienceManagement.isReady()&&(c+=a.q("d",a.AudienceManagement.getEventCallConfigParams()));for(d=0;d<b.length;d++){e=b[d];g=a[e];f=e.substring(0,4);h=e.substring(4);g||("events"==e&&n?(g=n,n=""):"marketingCloudOrgID"==e&&a.visitor&&(g=a.visitor.marketingCloudOrgID));if(g&&(!q||0<=q.indexOf(","+e+","))){switch(e){case "customerPerspective":e="cp";break;case "marketingCloudOrgID":e="mcorgid";break;case "supplementalDataID":e="sdid";break;case "timestamp":e="ts";break;case "dynamicVariablePrefix":e=
"D";break;case "visitorID":e="vid";break;case "marketingCloudVisitorID":e="mid";break;case "analyticsVisitorID":e="aid";break;case "audienceManagerLocationHint":e="aamlh";break;case "audienceManagerBlob":e="aamb";break;case "authState":e="as";break;case "pageURL":e="g";255<g.length&&(a.pageURLRest=g.substring(255),g=g.substring(0,255));break;case "pageURLRest":e="-g";break;case "referrer":e="r";break;case "vmk":case "visitorMigrationKey":e="vmt";break;case "visitorMigrationServer":e="vmf";a.ssl&&
a.visitorMigrationServerSecure&&(g="");break;case "visitorMigrationServerSecure":e="vmf";!a.ssl&&a.visitorMigrationServer&&(g="");break;case "charSet":e="ce";break;case "visitorNamespace":e="ns";break;case "cookieDomainPeriods":e="cdp";break;case "cookieLifetime":e="cl";break;case "variableProvider":e="vvp";break;case "currencyCode":e="cc";break;case "channel":e="ch";break;case "transactionID":e="xact";break;case "campaign":e="v0";break;case "latitude":e="lat";break;case "longitude":e="lon";break;
case "resolution":e="s";break;case "colorDepth":e="c";break;case "javascriptVersion":e="j";break;case "javaEnabled":e="v";break;case "cookiesEnabled":e="k";break;case "browserWidth":e="bw";break;case "browserHeight":e="bh";break;case "connectionType":e="ct";break;case "homepage":e="hp";break;case "events":n&&(g+=(""!=g?",":"")+n);if(m)for(h=g.split(","),g="",f=0;f<h.length;f++)l=h[f],k=l.indexOf("="),0<=k&&(l=l.substring(0,k)),k=l.indexOf(":"),0<=k&&(l=l.substring(0,k)),0<=m.indexOf(","+l+",")&&(g+=
(g?",":"")+h[f]);break;case "events2":g="";break;case "contextData":c+=a.q("c",a[e],q,e);g="";break;case "lightProfileID":e="mtp";break;case "lightStoreForSeconds":e="mtss";a.lightProfileID||(g="");break;case "lightIncrementBy":e="mti";a.lightProfileID||(g="");break;case "retrieveLightProfiles":e="mtsr";break;case "deleteLightProfiles":e="mtsd";break;case "retrieveLightData":a.retrieveLightProfiles&&(c+=a.q("mts",a[e],q,e));g="";break;default:a.Ha(h)&&("prop"==f?e="c"+h:"eVar"==f?e="v"+h:"list"==
f?e="l"+h:"hier"==f&&(e="h"+h,g=g.substring(0,255)))}g&&(c+="&"+e+"="+("pev"!=e.substring(0,3)?a.escape(g):g))}"pev3"==e&&a.e&&(c+=a.e)}a.fa&&(c+="&lrt="+a.fa,a.fa=null);return c};a.C=function(a){var b=a.tagName;if("undefined"!=""+a.Qb||"undefined"!=""+a.Gb&&"HTML"!=(""+a.Gb).toUpperCase())return"";b=b&&b.toUpperCase?b.toUpperCase():"";"SHAPE"==b&&(b="");b&&(("INPUT"==b||"BUTTON"==b)&&a.type&&a.type.toUpperCase?b=a.type.toUpperCase():!b&&a.href&&(b="A"));return b};a.Da=function(a){var b=k.location,
d=a.href?a.href:"",f,e,g;f=d.indexOf(":");e=d.indexOf("?");g=d.indexOf("/");d&&(0>f||0<=e&&f>e||0<=g&&f>g)&&(e=a.protocol&&1<a.protocol.length?a.protocol:b.protocol?b.protocol:"",f=b.pathname.lastIndexOf("/"),d=(e?e+"//":"")+(a.host?a.host:b.host?b.host:"")+("/"!=d.substring(0,1)?b.pathname.substring(0,0>f?0:f)+"/":"")+d);return d};a.M=function(c){var b=a.C(c),d,f,e="",g=0;return b&&(d=c.protocol,f=c.onclick,!c.href||"A"!=b&&"AREA"!=b||f&&d&&!(0>d.toLowerCase().indexOf("javascript"))?f?(e=a.replace(a.replace(a.replace(a.replace(""+
f,"\r",""),"\n",""),"\t","")," ",""),g=2):"INPUT"==b||"SUBMIT"==b?(c.value?e=c.value:c.innerText?e=c.innerText:c.textContent&&(e=c.textContent),g=3):"IMAGE"==b&&c.src&&(e=c.src):e=a.Da(c),e)?{id:e.substring(0,100),type:g}:0};a.Ob=function(c){for(var b=a.C(c),d=a.M(c);c&&!d&&"BODY"!=b;)if(c=c.parentElement?c.parentElement:c.parentNode)b=a.C(c),d=a.M(c);d&&"BODY"!=b||(c=0);c&&(b=c.onclick?""+c.onclick:"",0<=b.indexOf(".tl(")||0<=b.indexOf(".trackLink("))&&(c=0);return c};a.Fb=function(){var c,b,d=a.linkObject,
f=a.linkType,e=a.linkURL,g,h;a.ha=1;d||(a.ha=0,d=a.clickObject);if(d){c=a.C(d);for(b=a.M(d);d&&!b&&"BODY"!=c;)if(d=d.parentElement?d.parentElement:d.parentNode)c=a.C(d),b=a.M(d);b&&"BODY"!=c||(d=0);if(d&&!a.linkObject){var l=d.onclick?""+d.onclick:"";if(0<=l.indexOf(".tl(")||0<=l.indexOf(".trackLink("))d=0}}else a.ha=1;!e&&d&&(e=a.Da(d));e&&!a.linkLeaveQueryString&&(g=e.indexOf("?"),0<=g&&(e=e.substring(0,g)));if(!f&&e){var m=0,q=0,n;if(a.trackDownloadLinks&&a.linkDownloadFileTypes)for(l=e.toLowerCase(),
g=l.indexOf("?"),h=l.indexOf("#"),0<=g?0<=h&&h<g&&(g=h):g=h,0<=g&&(l=l.substring(0,g)),g=a.linkDownloadFileTypes.toLowerCase().split(","),h=0;h<g.length;h++)(n=g[h])&&l.substring(l.length-(n.length+1))=="."+n&&(f="d");if(a.trackExternalLinks&&!f&&(l=e.toLowerCase(),a.Ga(l)&&(a.linkInternalFilters||(a.linkInternalFilters=k.location.hostname),g=0,a.linkExternalFilters?(g=a.linkExternalFilters.toLowerCase().split(","),m=1):a.linkInternalFilters&&(g=a.linkInternalFilters.toLowerCase().split(",")),g))){for(h=
0;h<g.length;h++)n=g[h],0<=l.indexOf(n)&&(q=1);q?m&&(f="e"):m||(f="e")}}a.linkObject=d;a.linkURL=e;a.linkType=f;if(a.trackClickMap||a.trackInlineStats)a.e="",d&&(f=a.pageName,e=1,d=d.sourceIndex,f||(f=a.pageURL,e=0),k.s_objectID&&(b.id=k.s_objectID,d=b.type=1),f&&b&&b.id&&c&&(a.e="&pid="+a.escape(f.substring(0,255))+(e?"&pidt="+e:"")+"&oid="+a.escape(b.id.substring(0,100))+(b.type?"&oidt="+b.type:"")+"&ot="+c+(d?"&oi="+d:"")))};a.yb=function(){var c=a.ha,b=a.linkType,d=a.linkURL,f=a.linkName;b&&(d||
f)&&(b=b.toLowerCase(),"d"!=b&&"e"!=b&&(b="o"),a.pe="lnk_"+b,a.pev1=d?a.escape(d):"",a.pev2=f?a.escape(f):"",c=1);a.abort&&(c=0);if(a.trackClickMap||a.trackInlineStats||a.Bb()){var b={},d=0,e=a.cookieRead("s_sq"),g=e?e.split("&"):0,h,l,k,e=0;if(g)for(h=0;h<g.length;h++)l=g[h].split("="),f=a.unescape(l[0]).split(","),l=a.unescape(l[1]),b[l]=f;f=a.account.split(",");h={};for(k in a.contextData)k&&!Object.prototype[k]&&"a.activitymap."==k.substring(0,14)&&(h[k]=a.contextData[k],a.contextData[k]="");
a.e=a.q("c",h)+(a.e?a.e:"");if(c||a.e){c&&!a.e&&(e=1);for(l in b)if(!Object.prototype[l])for(k=0;k<f.length;k++)for(e&&(g=b[l].join(","),g==a.account&&(a.e+=("&"!=l.charAt(0)?"&":"")+l,b[l]=[],d=1)),h=0;h<b[l].length;h++)g=b[l][h],g==f[k]&&(e&&(a.e+="&u="+a.escape(g)+("&"!=l.charAt(0)?"&":"")+l+"&u=0"),b[l].splice(h,1),d=1);c||(d=1);if(d){e="";h=2;!c&&a.e&&(e=a.escape(f.join(","))+"="+a.escape(a.e),h=1);for(l in b)!Object.prototype[l]&&0<h&&0<b[l].length&&(e+=(e?"&":"")+a.escape(b[l].join(","))+"="+
a.escape(l),h--);a.cookieWrite("s_sq",e)}}}return c};a.zb=function(){if(!a.Jb){var c=new Date,b=n.location,d,f,e=f=d="",g="",h="",l="1.2",k=a.cookieWrite("s_cc","true",0)?"Y":"N",m="",p="";if(c.setUTCDate&&(l="1.3",(0).toPrecision&&(l="1.5",c=[],c.forEach))){l="1.6";f=0;d={};try{f=new Iterator(d),f.next&&(l="1.7",c.reduce&&(l="1.8",l.trim&&(l="1.8.1",Date.parse&&(l="1.8.2",Object.create&&(l="1.8.5")))))}catch(r){}}d=screen.width+"x"+screen.height;e=navigator.javaEnabled()?"Y":"N";f=screen.pixelDepth?
screen.pixelDepth:screen.colorDepth;g=a.w.innerWidth?a.w.innerWidth:a.d.documentElement.offsetWidth;h=a.w.innerHeight?a.w.innerHeight:a.d.documentElement.offsetHeight;try{a.b.addBehavior("#default#homePage"),m=a.b.Pb(b)?"Y":"N"}catch(s){}try{a.b.addBehavior("#default#clientCaps"),p=a.b.connectionType}catch(t){}a.resolution=d;a.colorDepth=f;a.javascriptVersion=l;a.javaEnabled=e;a.cookiesEnabled=k;a.browserWidth=g;a.browserHeight=h;a.connectionType=p;a.homepage=m;a.Jb=1}};a.Q={};a.loadModule=function(c,
b){var d=a.Q[c];if(!d){d=k["AppMeasurement_Module_"+c]?new k["AppMeasurement_Module_"+c](a):{};a.Q[c]=a[c]=d;d.ab=function(){return d.fb};d.hb=function(b){if(d.fb=b)a[c+"_onLoad"]=b,a.ba(c+"_onLoad",[a,d],1)||b(a,d)};try{Object.defineProperty?Object.defineProperty(d,"onLoad",{get:d.ab,set:d.hb}):d._olc=1}catch(f){d._olc=1}}b&&(a[c+"_onLoad"]=b,a.ba(c+"_onLoad",[a,d],1)||b(a,d))};a.o=function(c){var b,d;for(b in a.Q)if(!Object.prototype[b]&&(d=a.Q[b])&&(d._olc&&d.onLoad&&(d._olc=0,d.onLoad(a,d)),d[c]&&
d[c]()))return 1;return 0};a.Bb=function(){return a.ActivityMap&&a.ActivityMap._c?!0:!1};a.Cb=function(){var c=Math.floor(1E13*Math.random()),b=a.visitorSampling,d=a.visitorSamplingGroup,d="s_vsn_"+(a.visitorNamespace?a.visitorNamespace:a.account)+(d?"_"+d:""),f=a.cookieRead(d);if(b){b*=100;f&&(f=parseInt(f));if(!f){if(!a.cookieWrite(d,c))return 0;f=c}if(f%1E4>b)return 0}return 1};a.R=function(c,b){var d,f,e,g,h,l;for(d=0;2>d;d++)for(f=0<d?a.va:a.g,e=0;e<f.length;e++)if(g=f[e],(h=c[g])||c["!"+g]){if(!b&&
("contextData"==g||"retrieveLightData"==g)&&a[g])for(l in a[g])h[l]||(h[l]=a[g][l]);a[g]=h}};a.Ra=function(c,b){var d,f,e,g;for(d=0;2>d;d++)for(f=0<d?a.va:a.g,e=0;e<f.length;e++)g=f[e],c[g]=a[g],b||c[g]||(c["!"+g]=1)};a.tb=function(a){var b,d,f,e,g,h=0,l,k="",m="";if(a&&255<a.length&&(b=""+a,d=b.indexOf("?"),0<d&&(l=b.substring(d+1),b=b.substring(0,d),e=b.toLowerCase(),f=0,"http://"==e.substring(0,7)?f+=7:"https://"==e.substring(0,8)&&(f+=8),d=e.indexOf("/",f),0<d&&(e=e.substring(f,d),g=b.substring(d),
b=b.substring(0,d),0<=e.indexOf("google")?h=",q,ie,start,search_key,word,kw,cd,":0<=e.indexOf("yahoo.co")&&(h=",p,ei,"),h&&l)))){if((a=l.split("&"))&&1<a.length){for(f=0;f<a.length;f++)e=a[f],d=e.indexOf("="),0<d&&0<=h.indexOf(","+e.substring(0,d)+",")?k+=(k?"&":"")+e:m+=(m?"&":"")+e;k&&m?l=k+"&"+m:m=""}d=253-(l.length-m.length)-b.length;a=b+(0<d?g.substring(0,d):"")+"?"+l}return a};a.Va=function(c){var b=a.d.visibilityState,d=["webkitvisibilitychange","visibilitychange"];b||(b=a.d.webkitVisibilityState);
if(b&&"prerender"==b){if(c)for(b=0;b<d.length;b++)a.d.addEventListener(d[b],function(){var b=a.d.visibilityState;b||(b=a.d.webkitVisibilityState);"visible"==b&&c()});return!1}return!0};a.X=!1;a.J=!1;a.jb=function(){a.J=!0;a.H()};a.Y=!1;a.S=!1;a.kb=function(c){a.marketingCloudVisitorID=c.MCMID;a.visitorOptedOut=c.MCOPTOUT;a.analyticsVisitorID=c.MCAID;a.audienceManagerLocationHint=c.MCAAMLH;a.audienceManagerBlob=c.MCAAMB;a.S=!0;a.H()};a.Ua=function(c){a.maxDelay||(a.maxDelay=250);return a.o("_d")?(c&&
setTimeout(function(){c()},a.maxDelay),!1):!0};a.W=!1;a.I=!1;a.ra=function(){a.I=!0;a.H()};a.isReadyToTrack=function(){var c=!0,b=a.visitor;a.X||a.J||(a.Va(a.jb)?a.J=!0:a.X=!0);if(a.X&&!a.J)return!1;b&&b.isAllowed()&&(a.Y||a.marketingCloudVisitorID||!b.getVisitorValues||(a.Y=!0,a.marketingCloudVisitorID?a.S=!0:b.getVisitorValues(a.kb)),c=!a.Y||a.S||a.marketingCloudVisitorID?!0:!1);a.W||a.I||(a.Ua(a.ra)?a.I=!0:a.W=!0);a.W&&!a.I&&(c=!1);return c};a.l=p;a.r=0;a.callbackWhenReadyToTrack=function(c,b,
d){var f;f={};f.ob=c;f.nb=b;f.lb=d;a.l==p&&(a.l=[]);a.l.push(f);0==a.r&&(a.r=setInterval(a.H,100))};a.H=function(){var c;if(a.isReadyToTrack()&&(a.ib(),a.l!=p))for(;0<a.l.length;)c=a.l.shift(),c.nb.apply(c.ob,c.lb)};a.ib=function(){a.r&&(clearInterval(a.r),a.r=0)};a.cb=function(c){var b,d,f=p,e=p;if(!a.isReadyToTrack()){b=[];if(c!=p)for(d in f={},c)f[d]=c[d];e={};a.Ra(e,!0);b.push(f);b.push(e);a.callbackWhenReadyToTrack(a,a.track,b);return!0}return!1};a.vb=function(){var c=a.cookieRead("s_fid"),b=
"",d="",f;f=8;var e=4;if(!c||0>c.indexOf("-")){for(c=0;16>c;c++)f=Math.floor(Math.random()*f),b+="0123456789ABCDEF".substring(f,f+1),f=Math.floor(Math.random()*e),d+="0123456789ABCDEF".substring(f,f+1),f=e=16;c=b+"-"+d}a.cookieWrite("s_fid",c,1)||(c=0);return c};a.t=a.track=function(c,b){var d,f=new Date,e="s"+Math.floor(f.getTime()/108E5)%10+Math.floor(1E13*Math.random()),g=f.getYear(),g="t="+a.escape(f.getDate()+"/"+f.getMonth()+"/"+(1900>g?g+1900:g)+" "+f.getHours()+":"+f.getMinutes()+":"+f.getSeconds()+
" "+f.getDay()+" "+f.getTimezoneOffset());a.visitor&&a.visitor.getAuthState&&(a.authState=a.visitor.getAuthState());a.o("_s");a.cb(c)||(b&&a.R(b),c&&(d={},a.Ra(d,0),a.R(c)),a.Cb()&&!a.visitorOptedOut&&(a.pa()||(a.fid=a.vb()),a.Fb(),a.usePlugins&&a.doPlugins&&a.doPlugins(a),a.account&&(a.abort||(a.trackOffline&&!a.timestamp&&(a.timestamp=Math.floor(f.getTime()/1E3)),f=k.location,a.pageURL||(a.pageURL=f.href?f.href:f),a.referrer||a.Sa||(f=a.Util.getQueryParam("adobe_mc_ref",null,null,!0),a.referrer=
f||void 0===f?void 0===f?"":f:n.document.referrer),a.Sa=1,a.referrer=a.tb(a.referrer),a.o("_g")),a.yb()&&!a.abort&&(a.visitor&&!a.supplementalDataID&&a.visitor.getSupplementalDataID&&(a.supplementalDataID=a.visitor.getSupplementalDataID("AppMeasurement:"+a._in,a.expectSupplementalData?!1:!0)),a.zb(),g+=a.xb(),a.eb(e,g),a.o("_t"),a.referrer=""))),c&&a.R(d,1));a.abort=a.supplementalDataID=a.timestamp=a.pageURLRest=a.linkObject=a.clickObject=a.linkURL=a.linkName=a.linkType=k.s_objectID=a.pe=a.pev1=a.pev2=
a.pev3=a.e=a.lightProfileID=0};a.ua=[];a.registerPreTrackCallback=function(c){for(var b=[],d=1;d<arguments.length;d++)b.push(arguments[d]);"function"==typeof c?a.ua.push([c,b]):a.debugTracking&&a.D("DEBUG: Non function type passed to registerPreTrackCallback")};a.Ya=function(c){a.oa(a.ua,c)};a.ta=[];a.registerPostTrackCallback=function(c){for(var b=[],d=1;d<arguments.length;d++)b.push(arguments[d]);"function"==typeof c?a.ta.push([c,b]):a.debugTracking&&a.D("DEBUG: Non function type passed to registerPostTrackCallback")};
a.Xa=function(c){a.oa(a.ta,c)};a.oa=function(c,b){if("object"==typeof c)for(var d=0;d<c.length;d++){var f=c[d][0],e=c[d][1].slice();e.unshift(b);if("function"==typeof f)try{f.apply(null,e)}catch(g){a.debugTracking&&a.D(g.message)}}};a.tl=a.trackLink=function(c,b,d,f,e){a.linkObject=c;a.linkType=b;a.linkName=d;e&&(a.k=c,a.v=e);return a.track(f)};a.trackLight=function(c,b,d,f){a.lightProfileID=c;a.lightStoreForSeconds=b;a.lightIncrementBy=d;return a.track(f)};a.clearVars=function(){var c,b;for(c=0;c<
a.g.length;c++)if(b=a.g[c],"prop"==b.substring(0,4)||"eVar"==b.substring(0,4)||"hier"==b.substring(0,4)||"list"==b.substring(0,4)||"channel"==b||"events"==b||"eventList"==b||"products"==b||"productList"==b||"purchaseID"==b||"transactionID"==b||"state"==b||"zip"==b||"campaign"==b)a[b]=void 0};a.tagContainerMarker="";a.eb=function(c,b){var d=a.Za()+"/"+c+"?AQB=1&ndh=1&pf=1&"+(a.qa()?"callback=s_c_il["+a._in+"].doPostbacks&et=1&":"")+b+"&AQE=1";a.Ya(d);a.Wa(d);a.T()};a.Za=function(){var c=a.$a();return"http"+
(a.ssl?"s":"")+"://"+c+"/b/ss/"+a.account+"/"+(a.mobile?"5.":"")+(a.qa()?"10":"1")+"/JS-"+a.version+(a.Ib?"T":"")+(a.tagContainerMarker?"-"+a.tagContainerMarker:"")};a.qa=function(){return a.AudienceManagement&&a.AudienceManagement.isReady()||0!=a.usePostbacks};a.$a=function(){var c=a.dc,b=a.trackingServer;b?a.trackingServerSecure&&a.ssl&&(b=a.trackingServerSecure):(c=c?(""+c).toLowerCase():"d1","d1"==c?c="112":"d2"==c&&(c="122"),b=a.bb()+"."+c+".2o7.net");return b};a.bb=function(){var c=a.visitorNamespace;
c||(c=a.account.split(",")[0],c=c.replace(/[^0-9a-z]/gi,""));return c};a.Qa=/{(%?)(.*?)(%?)}/;a.Mb=RegExp(a.Qa.source,"g");a.sb=function(c){if("object"==typeof c.dests)for(var b=0;b<c.dests.length;++b){var d=c.dests[b];if("string"==typeof d.c&&"aa."==d.id.substr(0,3))for(var f=d.c.match(a.Mb),e=0;e<f.length;++e){var g=f[e],h=g.match(a.Qa),k="";"%"==h[1]&&"timezone_offset"==h[2]?k=(new Date).getTimezoneOffset():"%"==h[1]&&"timestampz"==h[2]&&(k=a.wb());d.c=d.c.replace(g,a.escape(k))}}};a.wb=function(){var c=
new Date,b=new Date(6E4*Math.abs(c.getTimezoneOffset()));return a.j(4,c.getFullYear())+"-"+a.j(2,c.getMonth()+1)+"-"+a.j(2,c.getDate())+"T"+a.j(2,c.getHours())+":"+a.j(2,c.getMinutes())+":"+a.j(2,c.getSeconds())+(0<c.getTimezoneOffset()?"-":"+")+a.j(2,b.getUTCHours())+":"+a.j(2,b.getUTCMinutes())};a.j=function(a,b){return(Array(a+1).join(0)+b).slice(-a)};a.la={};a.doPostbacks=function(c){if("object"==typeof c)if(a.sb(c),"object"==typeof a.AudienceManagement&&"function"==typeof a.AudienceManagement.isReady&&
a.AudienceManagement.isReady()&&"function"==typeof a.AudienceManagement.passData)a.AudienceManagement.passData(c);else if("object"==typeof c&&"object"==typeof c.dests)for(var b=0;b<c.dests.length;++b){var d=c.dests[b];"object"==typeof d&&"string"==typeof d.c&&"string"==typeof d.id&&"aa."==d.id.substr(0,3)&&(a.la[d.id]=new Image,a.la[d.id].alt="",a.la[d.id].src=d.c)}};a.Wa=function(c){a.i||a.Ab();a.i.push(c);a.ea=a.B();a.Oa()};a.Ab=function(){a.i=a.Db();a.i||(a.i=[])};a.Db=function(){var c,b;if(a.ka()){try{(b=
k.localStorage.getItem(a.ia()))&&(c=k.JSON.parse(b))}catch(d){}return c}};a.ka=function(){var c=!0;a.trackOffline&&a.offlineFilename&&k.localStorage&&k.JSON||(c=!1);return c};a.Ea=function(){var c=0;a.i&&(c=a.i.length);a.p&&c++;return c};a.T=function(){if(a.p&&(a.A&&a.A.complete&&a.A.F&&a.A.na(),a.p))return;a.Fa=p;if(a.ja)a.ea>a.O&&a.Ma(a.i),a.ma(500);else{var c=a.mb();if(0<c)a.ma(c);else if(c=a.Ba())a.p=1,a.Eb(c),a.Hb(c)}};a.ma=function(c){a.Fa||(c||(c=0),a.Fa=setTimeout(a.T,c))};a.mb=function(){var c;
if(!a.trackOffline||0>=a.offlineThrottleDelay)return 0;c=a.B()-a.Ka;return a.offlineThrottleDelay<c?0:a.offlineThrottleDelay-c};a.Ba=function(){if(0<a.i.length)return a.i.shift()};a.Eb=function(c){if(a.debugTracking){var b="AppMeasurement Debug: "+c;c=c.split("&");var d;for(d=0;d<c.length;d++)b+="\n\t"+a.unescape(c[d]);a.D(b)}};a.pa=function(){return a.marketingCloudVisitorID||a.analyticsVisitorID};a.V=!1;var t;try{t=JSON.parse('{"x":"y"}')}catch(w){t=null}t&&"y"==t.x?(a.V=!0,a.U=function(a){return JSON.parse(a)}):
k.$&&k.$.parseJSON?(a.U=function(a){return k.$.parseJSON(a)},a.V=!0):a.U=function(){return null};a.Hb=function(c){var b,d,f;a.pa()&&2047<c.length&&(a.Ta()&&(d=1,b=new XMLHttpRequest),b&&(a.AudienceManagement&&a.AudienceManagement.isReady()||0!=a.usePostbacks)&&(a.V?b.wa=!0:b=0));!b&&a.Pa&&(c=c.substring(0,2047));!b&&a.d.createElement&&(0!=a.usePostbacks||a.AudienceManagement&&a.AudienceManagement.isReady())&&(b=a.d.createElement("SCRIPT"))&&"async"in b&&((f=(f=a.d.getElementsByTagName("HEAD"))&&f[0]?
f[0]:a.d.body)?(b.type="text/javascript",b.setAttribute("async","async"),d=2):b=0);b||(b=new Image,b.alt="",b.abort||"undefined"===typeof k.InstallTrigger||(b.abort=function(){b.src=p}));b.La=Date.now();b.ya=function(){try{b.F&&(clearTimeout(b.F),b.F=0)}catch(a){}};b.onload=b.na=function(){b.La&&(a.fa=Date.now()-b.La);a.Xa(c);b.ya();a.qb();a.Z();a.p=0;a.T();if(b.wa){b.wa=!1;try{a.doPostbacks(a.U(b.responseText))}catch(d){}}};b.onabort=b.onerror=b.Ca=function(){b.ya();(a.trackOffline||a.ja)&&a.p&&
a.i.unshift(a.pb);a.p=0;a.ea>a.O&&a.Ma(a.i);a.Z();a.ma(500)};b.onreadystatechange=function(){4==b.readyState&&(200==b.status?b.na():b.Ca())};a.Ka=a.B();if(1==d)f=c.indexOf("?"),d=c.substring(0,f),f=c.substring(f+1),f=f.replace(/&callback=[a-zA-Z0-9_.\[\]]+/,""),b.open("POST",d,!0),b.withCredentials=!0,b.send(f);else if(b.src=c,2==d){if(a.Ia)try{f.removeChild(a.Ia)}catch(e){}f.firstChild?f.insertBefore(b,f.firstChild):f.appendChild(b);a.Ia=a.A}b.F=setTimeout(function(){b.F&&(b.complete?b.na():(a.trackOffline&&
b.abort&&b.abort(),b.Ca()))},5E3);a.pb=c;a.A=k["s_i_"+a.replace(a.account,",","_")]=b;if(a.useForcedLinkTracking&&a.K||a.v)a.forcedLinkTrackingTimeout||(a.forcedLinkTrackingTimeout=250),a.aa=setTimeout(a.Z,a.forcedLinkTrackingTimeout)};a.Ta=function(){return"undefined"!==typeof XMLHttpRequest&&"withCredentials"in new XMLHttpRequest?!0:!1};a.qb=function(){if(a.ka()&&!(a.Ja>a.O))try{k.localStorage.removeItem(a.ia()),a.Ja=a.B()}catch(c){}};a.Ma=function(c){if(a.ka()){a.Oa();try{k.localStorage.setItem(a.ia(),
k.JSON.stringify(c)),a.O=a.B()}catch(b){}}};a.Oa=function(){if(a.trackOffline){if(!a.offlineLimit||0>=a.offlineLimit)a.offlineLimit=10;for(;a.i.length>a.offlineLimit;)a.Ba()}};a.forceOffline=function(){a.ja=!0};a.forceOnline=function(){a.ja=!1};a.ia=function(){return a.offlineFilename+"-"+a.visitorNamespace+a.account};a.B=function(){return(new Date).getTime()};a.Ga=function(a){a=a.toLowerCase();return 0!=a.indexOf("#")&&0!=a.indexOf("about:")&&0!=a.indexOf("opera:")&&0!=a.indexOf("javascript:")?!0:
!1};a.setTagContainer=function(c){var b,d,f;a.Ib=c;for(b=0;b<a._il.length;b++)if((d=a._il[b])&&"s_l"==d._c&&d.tagContainerName==c){a.R(d);if(d.lmq)for(b=0;b<d.lmq.length;b++)f=d.lmq[b],a.loadModule(f.n);if(d.ml)for(f in d.ml)if(a[f])for(b in c=a[f],f=d.ml[f],f)!Object.prototype[b]&&("function"!=typeof f[b]||0>(""+f[b]).indexOf("s_c_il"))&&(c[b]=f[b]);if(d.mmq)for(b=0;b<d.mmq.length;b++)f=d.mmq[b],a[f.m]&&(c=a[f.m],c[f.f]&&"function"==typeof c[f.f]&&(f.a?c[f.f].apply(c,f.a):c[f.f].apply(c)));if(d.tq)for(b=
0;b<d.tq.length;b++)a.track(d.tq[b]);d.s=a;break}};a.Util={urlEncode:a.escape,urlDecode:a.unescape,cookieRead:a.cookieRead,cookieWrite:a.cookieWrite,getQueryParam:function(c,b,d,f){var e,g="";b||(b=a.pageURL?a.pageURL:k.location);d=d?d:"&";if(!c||!b)return g;b=""+b;e=b.indexOf("?");if(0>e)return g;b=d+b.substring(e+1)+d;if(!f||!(0<=b.indexOf(d+c+d)||0<=b.indexOf(d+c+"="+d))){e=b.indexOf("#");0<=e&&(b=b.substr(0,e)+d);e=b.indexOf(d+c+"=");if(0>e)return g;b=b.substring(e+d.length+c.length+1);e=b.indexOf(d);
0<=e&&(b=b.substring(0,e));0<b.length&&(g=a.unescape(b));return g}},getIeVersion:function(){if(document.documentMode)return document.documentMode;for(var a=7;4<a;a--){var b=document.createElement("div");b.innerHTML="\x3c!--[if IE "+a+"]><span></span><![endif]--\x3e";if(b.getElementsByTagName("span").length)return a}return null}};a.G="supplementalDataID timestamp dynamicVariablePrefix visitorID marketingCloudVisitorID analyticsVisitorID audienceManagerLocationHint authState fid vmk visitorMigrationKey visitorMigrationServer visitorMigrationServerSecure charSet visitorNamespace cookieDomainPeriods fpCookieDomainPeriods cookieLifetime pageName pageURL customerPerspective referrer contextData currencyCode lightProfileID lightStoreForSeconds lightIncrementBy retrieveLightProfiles deleteLightProfiles retrieveLightData".split(" ");
a.g=a.G.concat("purchaseID variableProvider channel server pageType transactionID campaign state zip events events2 products audienceManagerBlob tnt".split(" "));a.ga="timestamp charSet visitorNamespace cookieDomainPeriods cookieLifetime contextData lightProfileID lightStoreForSeconds lightIncrementBy".split(" ");a.P=a.ga.slice(0);a.va="account allAccounts debugTracking visitor visitorOptedOut trackOffline offlineLimit offlineThrottleDelay offlineFilename usePlugins doPlugins configURL visitorSampling visitorSamplingGroup linkObject clickObject linkURL linkName linkType trackDownloadLinks trackExternalLinks trackClickMap trackInlineStats linkLeaveQueryString linkTrackVars linkTrackEvents linkDownloadFileTypes linkExternalFilters linkInternalFilters useForcedLinkTracking forcedLinkTrackingTimeout trackingServer trackingServerSecure ssl abort mobile dc lightTrackVars maxDelay expectSupplementalData usePostbacks registerPreTrackCallback registerPostTrackCallback AudienceManagement".split(" ");
for(m=0;250>=m;m++)76>m&&(a.g.push("prop"+m),a.P.push("prop"+m)),a.g.push("eVar"+m),a.P.push("eVar"+m),6>m&&a.g.push("hier"+m),4>m&&a.g.push("list"+m);m="pe pev1 pev2 pev3 latitude longitude resolution colorDepth javascriptVersion javaEnabled cookiesEnabled browserWidth browserHeight connectionType homepage pageURLRest marketingCloudOrgID".split(" ");a.g=a.g.concat(m);a.G=a.G.concat(m);a.ssl=0<=k.location.protocol.toLowerCase().indexOf("https");a.charSet="UTF-8";a.contextData={};a.offlineThrottleDelay=
0;a.offlineFilename="AppMeasurement.offline";a.Ka=0;a.ea=0;a.O=0;a.Ja=0;a.linkDownloadFileTypes="exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";a.w=k;a.d=k.document;try{if(a.Pa=!1,navigator){var v=navigator.userAgent;if("Microsoft Internet Explorer"==navigator.appName||0<=v.indexOf("MSIE ")||0<=v.indexOf("Trident/")&&0<=v.indexOf("Windows NT 6"))a.Pa=!0}}catch(x){}a.Z=function(){a.aa&&(k.clearTimeout(a.aa),a.aa=p);a.k&&a.K&&a.k.dispatchEvent(a.K);a.v&&("function"==typeof a.v?a.v():
a.k&&a.k.href&&(a.d.location=a.k.href));a.k=a.K=a.v=0};a.Na=function(){a.b=a.d.body;a.b?(a.u=function(c){var b,d,f,e,g;if(!(a.d&&a.d.getElementById("cppXYctnr")||c&&c["s_fe_"+a._in])){if(a.xa)if(a.useForcedLinkTracking)a.b.removeEventListener("click",a.u,!1);else{a.b.removeEventListener("click",a.u,!0);a.xa=a.useForcedLinkTracking=0;return}else a.useForcedLinkTracking=0;a.clickObject=c.srcElement?c.srcElement:c.target;try{if(!a.clickObject||a.N&&a.N==a.clickObject||!(a.clickObject.tagName||a.clickObject.parentElement||
a.clickObject.parentNode))a.clickObject=0;else{var h=a.N=a.clickObject;a.da&&(clearTimeout(a.da),a.da=0);a.da=setTimeout(function(){a.N==h&&(a.N=0)},1E4);f=a.Ea();a.track();if(f<a.Ea()&&a.useForcedLinkTracking&&c.target){for(e=c.target;e&&e!=a.b&&"A"!=e.tagName.toUpperCase()&&"AREA"!=e.tagName.toUpperCase();)e=e.parentNode;if(e&&(g=e.href,a.Ga(g)||(g=0),d=e.target,c.target.dispatchEvent&&g&&(!d||"_self"==d||"_top"==d||"_parent"==d||k.name&&d==k.name))){try{b=a.d.createEvent("MouseEvents")}catch(l){b=
new k.MouseEvent}if(b){try{b.initMouseEvent("click",c.bubbles,c.cancelable,c.view,c.detail,c.screenX,c.screenY,c.clientX,c.clientY,c.ctrlKey,c.altKey,c.shiftKey,c.metaKey,c.button,c.relatedTarget)}catch(m){b=0}b&&(b["s_fe_"+a._in]=b.s_fe=1,c.stopPropagation(),c.stopImmediatePropagation&&c.stopImmediatePropagation(),c.preventDefault(),a.k=c.target,a.K=b)}}}}}catch(n){a.clickObject=0}}},a.b&&a.b.attachEvent?a.b.attachEvent("onclick",a.u):a.b&&a.b.addEventListener&&(navigator&&(0<=navigator.userAgent.indexOf("WebKit")&&
a.d.createEvent||0<=navigator.userAgent.indexOf("Firefox/2")&&k.MouseEvent)&&(a.xa=1,a.useForcedLinkTracking=1,a.b.addEventListener("click",a.u,!0)),a.b.addEventListener("click",a.u,!1))):setTimeout(a.Na,30)};a.rb();a.Rb||(r?a.setAccount(r):a.D("Error, missing Report Suite ID in AppMeasurement initialization"),a.Na(),a.loadModule("ActivityMap"))}
function s_gi(r){var a,k=window.s_c_il,p,n,m=r.split(","),s,u,t=0;if(k)for(p=0;!t&&p<k.length;){a=k[p];if("s_c"==a._c&&(a.account||a.oun))if(a.account&&a.account==r)t=1;else for(n=a.account?a.account:a.oun,n=a.allAccounts?a.allAccounts:n.split(","),s=0;s<m.length;s++)for(u=0;u<n.length;u++)m[s]==n[u]&&(t=1);p++}t?a.setAccount&&a.setAccount(r):a=new AppMeasurement(r);return a}AppMeasurement.getInstance=s_gi;window.s_objectID||(window.s_objectID=0);
function s_pgicq(){var r=window,a=r.s_giq,k,p,n;if(a)for(k=0;k<a.length;k++)p=a[k],n=s_gi(p.oun),n.setAccount(p.un),n.setTagContainer(p.tagContainerName);r.s_giq=0}s_pgicq();
