;
var uitk = (function (uitoolkit) {
    'use strict';

	var omnitureListVariables = {
		abTests: []
	};

	function resetOmnitureListVariables() {
		omnitureListVariables.abTests.length = 0;
	}

	function sendData() {
		if(typeof s_exp != 'undefined') {
			var s_code=s_exp.t();
			if(s_code){
				document.write(s_code);
			}

			//reset the list variables after sending to Omniture
			resetOmnitureListVariables();
		}
		else {
			console.log('s_exp not defined ');
		}
	}

	function setData(name, value) {
		if (typeof name === 'object') {
			// Apparently, the name parameter can also be an object of name/value pairs
			// Here we can add support for semantic name in this case also. 
			Object.keys(name).forEach((key) => {
			  setData(key, name[key]);
			});
		} else if (EG?.analytics?.omnitureVarMap?.[name]) {
			// There are many tracked items that map to multiple omniture vars so here
			// cal 'setPropInOmnitureObject" for each
			EG.analytics.omnitureVarMap[name].forEach((itemName) => {
				setPropInOmnitureObject(itemName, value);
			});
		} else {
			setPropInOmnitureObject(name, value);
		}
		return this;
	}

	function setPropInOmnitureObject(data, v) {
		const warningMessage = 'Omniture variables are case sensitive. Ex: eVar will work but evar will not. Please update accordingly.'
		const eVarCaseValidation = new RegExp('evar');

		if (typeof s_exp != 'undefined') {
			if (typeof data == 'string' || data instanceof String) {
				if (eVarCaseValidation.test(data)) {
					console.warn(warningMessage);
					uitk.logger.warn(warningMessage);
				}
				s_exp[data] = v;
			} else {
				uitk.logger.error("setPropInOmnitureObject: unexpected datatype");
			}
		}
		else {
			console.log('s_exp not defined ');
		}
	}

     function systemDate() {
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		if(dd<10) {
			dd = '0' + dd;
		}
		if(mm<10) {
			mm = '0' + mm;
		}
		today = yyyy+'/'+mm+'/'+dd;
		return today;
	}

	/**
	 * bootstrap the analytics properties with data populated from the EG
	 * analytics model provided from the server-side
	 * @return {Object} - this instance
	 */
	function init() {
		var variables = {};

		if (EG && EG.analytics) {
			// this will turn on the aa test for users with auth
			setAbTests('global_aa_test');

			// TODO: clean up after chat FAB placement testing is complete: EGE-338055
			setAbTests('chat.fab_enabled_for_application');

			// Add bootstrapped properties from the server (this mostly comes from the uitk_bap query param)
			// TODO: investigate removing uitk_bap processing from the server to the client
			if (EG.analytics.bootstrappedProperties) {
				setData(EG.analytics.bootstrappedProperties);
			}

			if (EG.user) {
					// data about the user's bucket
					variables.l1 = EG.user.bucketOverrideId;

					// user product id
					variables.prop7 = variables.eVar7 = EG.user.productId;

					// report the role of the current user
					variables.prop55 = variables.eVar55 = EG.user.roleType;

					// data about the travelers
					variables.prop11 = variables.eVar11 = EG.user.userId?.toString();
					if (EG.globalContext) {
							if (EG.globalContext.arrangees.length > 0) {
									variables.prop11 = variables.eVar11 = EG.globalContext.arrangees.map(function(arrangee) {
											return arrangee.get('userId');
									}).join(',');
							}

							// get the company id (or managed company id if it's null)
							variables.prop17 = variables.eVar22 = EG.user.companyId;
							if (EG.globalContext.get('managedCompanyId')) {
									variables.prop17 = variables.eVar22 = EG.globalContext.get('managedCompanyId') || EG.user.companyId;
							}
					}
			}

			// authenticated userid
			if (EG.authenticatedEntity) {
					variables.prop14 = variables.eVar14 = EG.authenticatedEntity.userId;
			}

			// self or arranger mode
			if (EG.user && EG.authenticatedEntity) {
				variables.eVar110 = EG.user?.userId === EG.authenticatedEntity?.userId ? 'self mode' : 'arranger mode'
			}

			// report locale
			variables.prop30 = variables.eVar54 = EG.currentLocale;

			// report application name
			variables.prop31 =  EG.appName || (EG.commonClientHeaders
										? EG.commonClientHeaders["transaction_source"]
										: window.location.pathname) //fallback is the same value but not sematically ideal.
			
			// pagename attribute
			variables.pageName = EG.pageName;

			setData(variables);
		}

		setData('campaign', s_exp.getQueryParam('cmp'));
		setData('prop43', getBrowserTabId())
	}

	function mapFriendlyNamesToOmnitureVars(linkTrackVars) {
		if (linkTrackVars) {
			const newTrackVars = linkTrackVars.split(',').reduce((acc, name) => {
				if (EG?.analytics?.omnitureVarMap?.[name]) {
					EG.analytics.omnitureVarMap[name].forEach((omnitureVarName) => {
						acc.push(omnitureVarName);
					});
				} else {
					if (name) {
						acc.push(name);
					}
				}
				return acc;
			}, []);
			return  newTrackVars;
		}
	}

	/**
	 * Send data to omniture after original page load send.
	 * 
	 * Uses s_exp.tl to send data
	 *
	 * @param {string} [type = "o"] - custom link.
	 * @param {object} [options.linkObj = true] - Object being clicked, if it has a href property.
     *      This is used for deciding whether or not to add a delay in case the user action causes the user to navigate away from the page.
	 * @param {string} [options.linkName] - Custom value, determines how the link is displayed in report.
	 * @param {string} [options.linkTrackVars = "prop7,eVar7,prop11,eVar11,prop12,prop14,eVar14,prop16,prop17,eVar18,eVar22,eVar28,prop30,prop37,prop38,eVar38,eVar50,eVar54,prop55,eVar55,eVar61,prop75,events,channel,l1"] - A string with comma separated variable names to send to Omniture for the type of action.
     *      Additional linkTrackVars will be added to the default track variables.
     * @param {string} [options.linkTrackEvents = "None"] - A string with comma separated event names to send to Omniture for this action.
     * @param {string} [options.linkId] - The link that was clicked on. This gets populated to prop16 and eVar28.
     * @param {string} [options.errorkey] - A String of error key. This gets populate to prop35.
     * @param {string} [options.errorMessage] - A String of error message key. This gets populate to prop36.
     *
	 */
	const defaultTrackVars = ['prop7','eVar7','prop11','eVar11','prop14','eVar14','prop16','prop17','eVar17','eVar22','eVar28','prop30','eVar50','eVar54','prop43','prop55','eVar55','eVar61'];
	const defaultLinkTrackVars = defaultTrackVars.concat(['prop12','prop16','eVar18','eVar28','prop37','prop38','eVar38','prop75','events','channel']);
	const defaultExitLinkTrackVars = defaultLinkTrackVars.concat(['eVar18','prop75']);
	const defaultDownloadLinkTrackVars = defaultTrackVars.concat(['prop12','eVar18']);
	const defaultErrorTrackVars = defaultLinkTrackVars.concat(['prop35', 'prop36']);

	function track(type, options) {
		options = options || {};

		let linkObj = options.linkObj || true; //default to true if no value
		let linkType = 'o'; //default to custom linktype (Other)
		let linkName = options.linkName;
		let linkTrackVars = options.linkTrackVars 
						? defaultLinkTrackVars.concat(mapFriendlyNamesToOmnitureVars(options.linkTrackVars))
						: defaultLinkTrackVars;
		let linkTrackEvents = options.linkTrackEvents || 'None';
		let shouldTrack = true;

		switch(type) {
			case 'e': //External Link
				linkType = 'e';
				linkName = 'RFRR Exit Link';
				s_exp.trackExternalLinks = false; // used to avoid doublecounting
				linkTrackVars = defaultExitLinkTrackVars;
				s_exp.prop16 = options.linkId;
				s_exp.eVar28 = options.linkId;
				break;
			case 'd': //Download link
				linkType = 'd';
				linkName = 'INS:TermsLink Downloads';
				s_exp.trackDownloadLinks = false;
				linkTrackVars = defaultDownloadLinkTrackVars;
				s_exp.prop16 = options.linkId;
				s_exp.eVar28 = options.linkId;
				break;
			case 'a':
				//'a' is a virtual type, it's actually an 'o' with some special overrides to name and tracking vars
				linkName = 'RFRR Action Link';
				linkTrackEvents = 'event33,event34';
				s_exp.prop16 = options.linkId;
				s_exp.eVar28 = options.linkId;
				break;
			case 'abtest':
				//'abtest' is a virtual type, it's actually an 'o' with special overrides to name and tracking vars
				// Validate if setABtest list1 is empty due to missing bucket and/or abTestId.
				if(s_exp['list1']) {
					linkTrackVars = ['list1'];
					linkName = 'Egencia AB Test';
				} else {
					shouldTrack = false;
				}
				break;
			case 'error':
				//'error' is a virtual type, it's actually an 'o' with special overrides to name and tracking vars
				linkName = 'Error Message Key';
				linkTrackVars = defaultErrorTrackVars;
				s_exp.prop35 = options.errorMessage
								? options.errorkey?? ''
								: '';
				s_exp.prop36 = options.errorMessage
								? options.errorMessage
								: options.errorkey;
				break;
		}

		s_exp.linkTrackVars = linkTrackVars.join(',');
		s_exp.linkTrackEvents = linkTrackEvents;

		if(shouldTrack) {
			s_exp.tl(linkObj, linkType, linkName);
		}

		//reset the list variables after sending to Omniture
		resetOmnitureListVariables();
	}


	/**
	 * Sets list1 property with relevant ab test values for configs
	 * Evaluates to confirm that the ab test is active for that config before setting it in var
	 * @param {...String} configs - List of config items to enable for ab tests
	 * @return {Object} - Returns self
	 */
	function setAbTests(...configs) {
		if (EG && EG.analytics && EG.analytics.configs) {
			// get the list of tests to add to the 'list1' variable
			// then push them to the omnitureListVariables list
			configs.map((config = '') => EG.analytics.configs[config])
				.filter((bucketedValue = {}) => bucketedValue && bucketedValue.bucket && bucketedValue.abTestId)
				.map((bucketedValue) => `${bucketedValue.abTestId}.${bucketedValue.bucket}`)
				.forEach((testId) => omnitureListVariables.abTests.push(testId));

			// join the tests on a comma and set it onto s_exp
			setData('list1', omnitureListVariables.abTests.join(','));

			if(!EG.pageName){
                console.error('pageName should not be empty');
			}
		}

		// return self
		return this;
	}

	function setUserSegmentation() {
		if(!EG?.authenticatedEntity?.userId) return Promise.resolve(void 0);
		return new Promise((resolve, rej) => {
			let segmentationSessionStorage = sessionStorage.getItem('uitk.userSegmentation');
			//fetch segmentation service once per user session and set it session storage and omniture variable list3
			if(segmentationSessionStorage == null){
				setTimeout(() => {
					fetch('/segmentation-service/v1/segmentations/user')
						.then(response => response.json())
						.then(segmentation => {
							sessionStorage.setItem("uitk.userSegmentation", segmentation.map(data => data.m_id +":"+ data.s_id).join("|"));
							resolve();
						})
						.catch(e => {
							console.warn("Could not contact segmentation endpoint", e);
						});
				},10);
			} else {
				//set list3 with session storage uitk.userSegmentation data
				setData('list3', segmentationSessionStorage);
				resolve();
			}
		});
	}

	function getUniqueSearchId(useCaseDescription = 'NoUseCaseDefined') {
		const sExp = s_exp
		let uniqueSearchID = ''
		if (sExp) {
			const mcid = sExp.marketingCloudVisitorID
			const loggedUser = sExp.eVar14
			const travelerIds = sExp.eVar11 ? sExp.eVar11.replace(',', '') : loggedUser
			const ts = Date.now()
			uniqueSearchID = mcid + travelerIds + loggedUser + ts
		} else {
			uniqueSearchID = 'Err_InitUniqueID_' + useCaseDescription
		}
		return uniqueSearchID
	}

	function getBrowserTabId () {
		if (sessionStorage.getItem("uitk.tab_id")) {
			return sessionStorage.getItem("uitk.tab_id")
		} else {
			const int32a = new Uint32Array(1)
			const crypto = window.crypto || window.msCrypto;
			crypto.getRandomValues(int32a)
			const tabID = int32a[0]
			sessionStorage.setItem("uitk.tab_id", `${tabID}`)
			return tabID
		}
	}

	init();
	setUserSegmentation();

    // Exposes Analytic API
	uitoolkit.analytics = {
		set: setData,
		setAbTests: setAbTests,
		send: sendData,
		track: track,
		systemDate: systemDate,
		getUniqueSearchId: getUniqueSearchId,
		
		// private
		_init: init,
		_setUserSegmentation: setUserSegmentation,
		_resetOmnitureListVariables: resetOmnitureListVariables,
	};

	return uitoolkit;

})(uitk || {});